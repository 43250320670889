.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-menu-item-icon{
  font-size: 25px !important;
}

.custom-input-design {
  border: 1px solid #998b8bb8 !important;
  border-radius: 5px !important;
}